import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/antonio-escritorio/Documents/moare/sites-estaticos/libres-de-violencias/src/components/default-mdx-layout.js";
import ContainerNarrow from "../components/container-narrow";
import { Box, Themed } from "theme-ui";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ContainerNarrow mdxType="ContainerNarrow">
      <Box sx={{
        marginTop: 6,
        h1: {
          borderBottom: theme => `1px solid ${theme.colors.primarylight}`,
          pb: 5
        }
      }} mdxType="Box">
        <h1>{`Presentación`}</h1>
      </Box>
      <p>{`En nombre de las organizadoras: `}<strong parentName="p"><Themed.a href="https://www.entrepueblos.org/">{`Entrepobles-Entrepueblos-Entrepobos-Herriarte`}</Themed.a></strong>{` y `}<strong parentName="p"><Themed.a href="https://www.curcuma.coop/es/portada/" rel="external">{`Cúrcuma`}</Themed.a></strong>{` os damos la bienvenida a este repositorio online de las Jornadas Internacionales «`}<strong parentName="p">{`Construyamos territorios libres de violencias machistas`}</strong>{`» con voces desde Cataluña, Estado Español y América Latina.`}</p>
      <p>{`Queremos agradecer la `}<strong parentName="p">{`participación a todas las personas inscritas`}</strong>{`, y a las que nos acompañaron en formato presencial y virtual y a las que ahora podéis acceder a todo el material construido durante las jornadas. `}</p>
      <p><strong parentName="p">{`El doble formato presencial y  streaming nos facilita un diálogo necesario`}</strong>{` a partir de experiencias, aprendizajes y retos en los diferentes contextos locales de las participantes, tanto del Estado Español como de América Latina para el abordaje de las violencias machistas.`}</p>
      <p>{`Dejar atrás la pandemia del COVID, nos permite respirar, pero sus impactos todavía hoy están presentes, y han sido devastadores. Nuestro recuerdo a todas las personas que ya no están con nosotras, y nuestro apoyo a todas esas situaciones de duelo que no pudieron compartirse. `}</p>
      <p>{`No sé si en esta vuelta a la «normalidad» estamos desaprovechado algunos aprendizajes, uno importante la necesidad de salir de forma colectiva de las crisis. Las consecuencias en el ámbito de las violencias machistas no sé si las llegaremos a dimensionar y si somos conscientes que también en este caso son importantes las respuestas comunitarias. `}</p>
      <p>{`Ahora centrándonos en los contenidos de las Jornadas y en los objetivos que nos marcamos desde un inicio.`}</p>
      <p>{`El título de las jornadas «`}<strong parentName="p">{`Construyamos territorios libres de violencias machistas`}</strong>{`» con voces desde Cataluña, Estado Español y América Latina, responden a la experiencia i al «pensamiento situado» de quienes somos las organizadoras de las jornadas. `}</p>
      <p>{`Entrepueblos somos una asociación de solidaridad internacional independiente, feminista, con base social activista, constituida en 1988 por los Comités de Solidaridad con América Latina, estamos comprometidas con la `}<strong parentName="p">{`transformación social`}</strong>{` junto a organizaciones y movimientos sociales alternativos de todo el mundo, la construcción de `}<strong parentName="p">{`alianzas y redes`}</strong>{` de apoyo mutuo son imprescindibles para el pleno ejercicio de las soberanías sociales, de los derechos humanos y de la naturaleza.`}</p>
      <p>{`Cúrcuma, es una cooperativa feminista del ámbito psicosocioeducativo, que trabaja en el ámbito de la `}<strong parentName="p">{`prevención`}</strong>{` de las `}<strong parentName="p">{`violencias machistas`}</strong>{` y el `}<strong parentName="p">{`acoso escolar`}</strong>{` y hace `}<strong parentName="p">{`acompañamientos a personas`}</strong>{` y `}<strong parentName="p">{`grupos`}</strong>{`.`}</p>
      <p>{`Nuestras sinergias y confianzas desde hace tiempo han hecho posible este trabajo conjunto.  Partimos de una visión compartida de que las violencias machistas son complejas, sistémicas y estructurales, que están atravesadas por diferentes opresiones de género, clase, racismo, heteronormatividad, capacitismo. No todas las mujeres viven las mismas violencias ni en la misma intensidad.  El concepto de territorio en este caso está basado en la necesidad de trabajar desde una perspectiva comunitaria en un sentido muy amplio, desde unidades de convivencia, comunidades de pueblos originarios, entornos laborales, escolares, universitarios, pueblos, ciudades. Una responsabilidad por parte de las instituciones públicas y una corresponsabilidad de la sociedad. `}</p>
      <p>{`Porqué hemos elegido estos tres ejes`}</p>
      <p><strong parentName="p">{`1. Maternidades y retiradas de custodias: violencia institucional, racismo y SAP`}</strong></p>
      <p>{`Explicar que hace tres años las compañeras de Cotidiano Mujer de Uruguay con quien tenemos una relación de tiempo, nos pidieron apoyo para el acompañamiento del caso de Maria (arrancamiento de la custodia de su hija). En ese momento querían hacer un seminario para visibilizar esta problemática que afecta a muchas madres- En aquel momento tuvimos inseguridad, no conocíamos mucho el tema y tampoco la red organizada para impulsarlo conjuntamente. Las cosas han cambiado mucho, felicitar a las madres afectadas que han sido capaces de autoorganizarse poner en la agenda política y en la agenda del feminismo este tema pedimos disculpas por este retraso.`}</p>
      <p>{`También nuestra participación en espacios de solidaridad con compañeras en procesos migratorios, hemos sido testimonio de la vulneración de derechos en el tema de las custodias, atravesadas con una violencia institucional terrible. `}</p>
      <p>{`Nuestra contribución:`}</p>
      <ul>
        <li parentName="ul">{`Visibilizar toda la violencia institucional en la forma de maternidades cuestionadas y retirada de custodias. `}</li>
        <li parentName="ul">{`Compartir estrategias desde diferentes ámbitos nacionales e internacionales para luchar contra la retirada de custodias en base racista y / o machista: y ver posibilidades de construcción de redes y otros instrumentos`}</li>
      </ul>
      <p><strong parentName="p">{`2. Violencias sexuales en jóvenes y adolescentes (foco en ciberviolencias)`}</strong></p>
      <p>{`La elección de este tema tiene una base en el trabajo realizado en Perú durante más de 4 años, dónde hemos acompañado a las compañeras de Demus y Kalpa en colaboración con organizaciones del estado español AIETI y Enrraiza Derechos. «Vivir sin miedo», podéis consultar la web y ver todo el trabajo que han hecho desde las propias comunidades rurales, un clip Mujer Montaña en la que han participado cantantes y youtuberas conocidas en Perú.`}</p>
      <ul>
        <li parentName="ul">{`Visibilizar las violencias sexuales que viven jóvenes y adolescentes en diferentes espacios: XXSS, universidades, espacios de ocio, etc`}</li>
        <li parentName="ul">{`Aprender de las experiencias de las jóvenes y adolescentes ante las violencias sexuales desde diferentes geografías`}</li>
      </ul>
      <p><strong parentName="p">{`3. Principales retos y debates en torno a las violencias machistas desde las entidades y colectivos feministas`}</strong>{`.`}</p>
      <p>{`La diversidad de miradas de las ponentes:  procedencias, ámbitos de intervención, diversidad de identidades culturales, pero muchas cosas en común, son defensoras de los derechos humanos desde una perspectiva feminista, en sus trayectorias han demostrado. tenacidad y convicciones en sus acciones feministas pese a las consecuencias. `}</p>
      <ul>
        <li parentName="ul">{`Identificar los principales retos globales de los feminismos en relación a las múltiples formas que toman las violencias machistas`}</li>
        <li parentName="ul">{`Aterrizar estos retos a los diferentes contextos locales de las participantes, tanto de España como de América Latina`}</li>
      </ul>
      <p>{`Para terminar, hacer referencia a que todo esto ha sido posible gracias a la financiación de la Agencia Catalana de Cooperació Internacional de la Generalitat de Catalunya y de la Agencia Española de Cooperación.`}</p>
      <p>{`Y un agradecimiento especial a todo el equipo de L’Espai Jove la Fontana por su colaboración en la cesión de espacio, a las compañeras encargadas del streaming: la cooperativa QUESONI y las organizaciones amigas en este convenio: AIETI, Enrraiza Derechos, DEMUS, Kalpa.`}</p>
    </ContainerNarrow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      